.no-scroll {
  overflow: hidden;
  touch-action: none;
  height: 100%;
  position: relative;
}

.no-scroll:after {
  content: "";
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0;
  animation: showOverlay 0.5s forwards;
}

@keyframes showOverlay {
  40% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
}

.mobile-navbar {
  overflow-y: auto;
  max-height: 100vh;
  opacity: 0;
  z-index: -1;
  transform: translateY(20px);
  top: 91px;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.mobile-navbar.show {
  z-index: 20;
  opacity: 1;
  transform: translateY(0);
}

.mobile-navbar ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.mobile-navbar li.group {
  border-bottom: 1px solid #e0e0e0;
}

.mobile-navbar li.group:last-child {
  border-bottom: 0;
}

.mobile-navbar .dropdown-menu li {
  padding: 10px 0 10px 20px;
}

.mobile-navbar .dropdown-menu li:last-child {
  padding-bottom: 0;
}

.menu-icon-container {
  top: 33px;
}

@media (max-width: 1279px) {
  .desktop-navbar {
    background-color: white !important;
  }
  
  .desktop-navbar img {
    display: none !important;
  }

  .desktop-navbar img.mobile-logo {
    display: block !important;
  }
}

@media (max-width: 768px) {
  .mobile-navbar {
    top: 67px;
  }

  .menu-icon-container {
    top: 22px;
  }
}
