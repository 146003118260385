.hamburglar {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  user-select: none;
}

.burger-icon {
  position: relative;
  width: 30px;
  height: 24px;
}

.burger-container span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: #000;
  border-radius: 2px;
  transition: all 0.3s ease-in-out;
}

.burger-bun-top,
.burger-bun-bot {
  transform-origin: left center;
}

.burger-bun-top {
  top: 0;
}

.burger-filling {
  top: 10px;
  opacity: 1;
}

.burger-bun-bot {
  top: 20px;
}

.is-open .burger-bun-top {
  transform: translateY(0px) rotate(45deg);
}

.is-open .burger-filling {
  width: 0%;
  opacity: 0;
}

.is-open .burger-bun-bot {
  transform: translateY(1px) rotate(-45deg);
}
