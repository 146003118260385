@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Poppins", sans-serif;
}

#root {
  overflow: hidden;
}

section {
  position: relative;
}

@media (max-width: 1200px) {
  body {
    padding-top: 91px;
  }
}

@media (max-width: 768px) {
  body {
    padding-top: 67px;
  }
}